import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dayModal } from "../../Reducer/modalSlice";
import banner from "../../Asset/report1.png";
import ModalContent from "./ModalContent";
import { collection, doc, getDoc } from "firebase/firestore";

import { db } from "../../firebase"; // 'firebase' 파일에서 'db'를 가져옴

function RenewalModal() {
  const location = useLocation();
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modal);
  const [isOpen, setIsOpen] = useState(false);
  const [count1, setCount1] = useState("00");
  const [count2, setCount2] = useState("00");
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    setPathname(location.pathname);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname === "/home" || location.pathname === "/mobile") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    getCount();
    /*
    if (!pathname) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    if (modalState.lastOpen === "") {
      setIsOpen(true);
      dispatch(
        dayModal({
          oneDay: "N",
          lastOpen: new Date(),
        })
      );
    } else {
      isAfterDay(modalState.lastOpen, modalState.oneDay);
    }
      */
    //eslint-disable-next-line
  }, [location]);

  const getCount = async () => {
    try {
      const docRef = doc(collection(db, "reportcount"), "count"); // "count" 문서 참조
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { num1, num2 } = docSnap.data();
        setCount1(num1);
        setCount2(num2);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  };

  const isAfterDay = (lastOpen, oneDay) => {
    const lastOpenDate = new Date(lastOpen);
    const currentDate = new Date();

    //하루, 5분설정
    const oneDayDifference = currentDate - lastOpenDate > 24 * 60 * 60 * 1000;
    const fiveMinutesDifference = currentDate - lastOpenDate > 1 * 60 * 1000;

    //1분, 1분설정
    //const oneDayDifference = currentDate - lastOpenDate > 60 * 1000;
    //const fiveMinutesDifference = currentDate - lastOpenDate > 60 * 1000;
    if (oneDay === "Y") {
      if (oneDayDifference) {
        setIsOpen(true);
        dispatch(
          dayModal({
            oneDay: "N",
            lastOpen: new Date(),
          })
        );
      } else {
        return false;
      }
    } else {
      if (fiveMinutesDifference) {
        setIsOpen(true);
        dispatch(
          dayModal({
            oneDay: "N",
            lastOpen: new Date(),
          })
        );
      } else {
        return false;
      }
    }
  };

  const closeIt = () => {
    setIsOpen(false);
    dispatch(
      dayModal({
        oneDay: "N",
        lastOpen: new Date(),
      })
    );
  };
  /*
  const closeOneDay = () => {
    setIsOpen(false);
    dispatch(
      dayModal({
        oneDay: "Y",
        lastOpen: new Date(),
      })
    );
  };
  */
  return (
    <>
      {isOpen ? (
        <div id="renewalmodal" className="w-fit">
          <button
            className="absolute w-9 h-9 lg:w-12 lg:h-12 top-2 right-2 lg:top-5 lg:right-5 text-white text-lg lg:text-xl txtShadow"
            onClick={closeIt}
          >
            X
          </button>
          <ModalContent img={banner} />
          <div className="absolute w-full bottom-1 lg:bottom-4 left-0 text-center p-2 text-xs lg:text-2xl text-white tracking-tight txtShadow">
            <div className="text-center pr-3 lg:pr-4">
              현재 공동참여자
              <span className="font-bold mx-1">{count1}</span>
              명&nbsp; | &nbsp;피해사례
              <span className="font-bold mx-1">{count2}</span>건 접수중
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default RenewalModal;
