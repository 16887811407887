import React from "react";

function ModalContent(props) {
  return (
    <div
      className="w-[95vw] lg:w-fit h-fit bg-transparent hover:cursor-pointer"
      onClick={() => {
        window.location.href = "tel:02-1644-4223";
      }}
    >
      <img
        src={props.img}
        alt="코리아티엠 사칭신고"
        className="w-[700px] max-w-full h-auto"
      />
    </div>
  );
}

export default ModalContent;
