import React from "react";
import intro from "../../../Asset/Intro/intromobile.png";

function MobileIntroDetail() {
  return (
    <div className="w-full max-w-fit">
      <img src={intro} alt="코리아티엠 소개" className="w-full h-auto" />
    </div>
  );
}

export default MobileIntroDetail;
