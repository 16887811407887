import React from "react";
import detail01 from "../../Asset/Intro/detail_01.png";
import detail02 from "../../Asset/Intro/detail_02.png";
import detail03 from "../../Asset/Intro/detail_03.png";
import detail04 from "../../Asset/Intro/detail_04.png";
import detail05 from "../../Asset/Intro/detail_05.png";
import detail06 from "../../Asset/Intro/detail_06.png";
import detail07 from "../../Asset/Intro/detail_07.png";
import detail08 from "../../Asset/Intro/detail_08.png";
import detail09 from "../../Asset/Intro/detail_09.png";
import detail10 from "../../Asset/Intro/detail_10.png";
import detail11 from "../../Asset/Intro/detail_11.png";
import detail12 from "../../Asset/Intro/detail_12.png";

function IntroDetail() {
  return (
    <div className="w-full grid grid-cols-1">
      <img src={detail01} alt="코리아티엠 소개" className="w-full" />
      <img src={detail02} alt="코리아티엠 소개" className="w-full" />
      <img src={detail03} alt="코리아티엠 소개" className="w-full" />
      <img src={detail04} alt="코리아티엠 소개" className="w-full" />
      <img src={detail05} alt="코리아티엠 소개" className="w-full" />
      <img src={detail06} alt="코리아티엠 소개" className="w-full" />
      <img src={detail07} alt="코리아티엠 소개" className="w-full" />
      <img src={detail08} alt="코리아티엠 소개" className="w-full" />
      <img src={detail09} alt="코리아티엠 소개" className="w-full" />
      <img src={detail10} alt="코리아티엠 소개" className="w-full" />
      <img src={detail11} alt="코리아티엠 소개" className="w-full" />
      <img src={detail12} alt="코리아티엠 소개" className="w-full" />
    </div>
  );
}

export default IntroDetail;
