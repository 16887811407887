import React from "react";

function IntroFocus() {
  return (
    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-fit min-w-[100px] h-auto text-center">
      준비중입니다
    </div>
  );
}

export default IntroFocus;
