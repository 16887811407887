import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import { useSelector } from "react-redux";

function AdminPwd() {
  const user = useSelector(state => state.user);
  let navi = useNavigate();
  const [id, setId] = useState(""); // 현재 비밀번호
  const [currentPw, setCurrentPw] = useState(""); // 현재 비밀번호
  const [newPw, setNewPw] = useState(""); // 새 비밀번호
  const [confirmPw, setConfirmPw] = useState(""); // 새 비밀번호 확인
  const [error, setError] = useState(""); // 오류 메시지
  const [success, setSuccess] = useState(false); // 성공 메시지

  useEffect(() => {
    const id = auth.currentUser.email.split("@")[0];
    setId(id);

    if (user.uid === "") {
      navi(-1); // 로그인되지 않은 경우 이전 페이지로 이동
    }
  }, [user.uid, navi]);

  const handleReauthenticateAndChangePassword = async e => {
    e.preventDefault();

    if (newPw.length < 6) {
      setError("비밀번호는 6자 이상이어야 합니다.");
      return;
    }
    if (newPw !== confirmPw) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      const currentUser = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPw // 사용자가 입력한 현재 비밀번호
      );

      // 사용자를 재인증합니다.
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPw);

      setSuccess(true);
      setError(""); // 오류 초기화

      setTimeout(() => {
        signOut(auth); // 로그아웃
        navi("/adminlogin"); // 로그인 페이지로 이동
      }, 3000);
    } catch (error) {
      setError("오류 발생: " + error.message);
    }
  };

  return (
    <div
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    p-3 bg-white rounded-lg min-w-1 min-h-1 drop-shadow-lg w-11/12 lg:w-2/6"
    >
      <h2 className="text-lg mb-3">관리자 {id}님의 비밀번호 변경</h2>
      <form onSubmit={handleReauthenticateAndChangePassword}>
        <div className="mb-3">
          <label
            htmlFor="current-password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            현재 비밀번호
          </label>
          <input
            type="password"
            id="current-password"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
            value={currentPw}
            disabled={success}
            onChange={e => setCurrentPw(e.currentTarget.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="new-password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            새 비밀번호 (6글자 이상)
          </label>
          <input
            type="password"
            id="new-password"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
            value={newPw}
            disabled={success}
            onChange={e => setNewPw(e.currentTarget.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="confirm-password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            새 비밀번호 확인
          </label>
          <input
            type="password"
            id="confirm-password"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
            value={confirmPw}
            onChange={e => setConfirmPw(e.currentTarget.value)}
            disabled={success}
            required
          />
        </div>
        {error && <p className="text-red-500 mb-3">{error}</p>}
        {success && (
          <p className="text-green-500 mb-3">
            비밀번호가 성공적으로 변경되었습니다. 잠시 후 로그아웃됩니다.
          </p>
        )}
        <button
          type="submit"
          className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
        >
          비밀번호 변경
        </button>
      </form>
    </div>
  );
}

export default AdminPwd;
